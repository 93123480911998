import {
  CardType,
  IChallengeResultInterface,
  IConfigInterface,
  ILogInterface,
  IMethodURLResultInterface,
  ThreeDSecureFactory,
  IThreeDSecureInterface,
  ThreeDSecureVersion,
} from "@trustpayments/3ds-sdk-js";
import { catchError, Observable, of, throwError } from "rxjs";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { Inject, Service } from "typedi";
import { PUBLIC_EVENTS } from "../../../application/core/models/constants/EventTypes";
import { IMessageBusEvent } from "../../../application/core/models/IMessageBusEvent";
import { type IInternalsMonitor } from "../../../application/core/services/monitoring/IInternalsMonitor";
import { IMessageBus } from "../../../application/core/shared/message-bus/IMessageBus";
import { Translator } from "../../../application/core/shared/translator/Translator";
import { InterFrameCommunicator } from "../../../shared/services/message-bus/InterFrameCommunicator";
import { ofType } from "../../../shared/services/message-bus/operators/ofType";
import { IChallengeData } from "./IChallengeData";
import { IMethodUrlData } from "./IMethodUrlData";

@Service()
export class ThreeDSecureClient {
  private threeDSecure: IThreeDSecureInterface;
  private destroy$: Observable<IMessageBusEvent<unknown>>;

  constructor(
    private interFrameCommunicator: InterFrameCommunicator,
    private threeDSecureFactory: ThreeDSecureFactory,
    private translator: Translator,
    private messageBus: IMessageBus,
    @Inject("IInternalsMonitor") private internalMonitor?: IInternalsMonitor,
  ) {
    this.destroy$ = this.messageBus.pipe(ofType(PUBLIC_EVENTS.DESTROY));
  }

  init(): void {
    this.threeDSecure = this.threeDSecureFactory.create();

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_INIT)
      .thenRespond((event: IMessageBusEvent<IConfigInterface>) =>
        this.init$(event.data),
      );

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_METHOD_URL)
      .thenRespond((event: IMessageBusEvent<IMethodUrlData>) =>
        this.run3DSMethod$(event.data),
      );

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_CHALLENGE)
      .thenRespond((event: IMessageBusEvent<IChallengeData>) =>
        this.doChallenge$(event.data),
      );

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_BROWSER_DATA)
      .thenRespond((event: IMessageBusEvent<any>) => {
        return new Observable<unknown>((observer) => {
          setTimeout(() => {
            this.threeDSecure
              .getBrowserData$(event.data.urls, event.data.requireFields)
              .subscribe({
                next: (data) => observer.next(data),
                error: (error) => observer.error(error),
                complete: () => observer.complete(),
              });
          }, 0); // Only added due to issues seen in STJS-5445 with android screen height.
        });
      });

    let minimumTimeToShowProcessingScreen = Promise.resolve();
    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_PROCESSING_SCREEN_SHOW)
      .thenRespond((event: IMessageBusEvent<string>) => {
        // As per EMVCo Req 176 - https://www.emvco.com/wp-content/uploads/awesome-support/ticket_195361/EMVCo_3DS_Spec_210_10171.pdf
        minimumTimeToShowProcessingScreen = new Promise((r) =>
          setTimeout(r, 2000),
        );
        return of(
          this.threeDSecure.showProcessingScreen(event.data as CardType, 0),
        );
      });

    this.interFrameCommunicator
      .whenReceive(PUBLIC_EVENTS.THREE_D_SECURE_PROCESSING_SCREEN_HIDE)
      .thenRespond(() => {
        minimumTimeToShowProcessingScreen.then(() =>
          this.threeDSecure.hideProcessingScreen(),
        );
        return of(undefined);
      });

    this.messageBus
      .pipe(ofType(PUBLIC_EVENTS.THREED_CANCEL), takeUntil(this.destroy$))
      .subscribe(() => this.cancel$());

    this.messageBus
      .pipe(
        ofType(PUBLIC_EVENTS.THREED_CANCEL),
        switchMap(() => this.cancel$()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private init$(config: IConfigInterface): Observable<IConfigInterface> {
    if (config.translations && config.translations.cancel) {
      return this.threeDSecure.init$(config).pipe(tap(() => this.initLogs$()));
    }

    const updatedConfig = {
      ...config,
      translations: {
        cancel: this.translator.translate("Cancel"),
      },
    };

    return this.threeDSecure.init$(updatedConfig).pipe(
      tap(() => this.initLogs$()),
      catchError((error) => {
        if (error && error.message) {
          error.message = `ThreeDSecure client: ThreeDSecure init: ${error.message}`;
        }
        this.internalMonitor.recordIssue(error);
        return throwError(() => error);
      }),
    );
  }

  private run3DSMethod$({
    methodUrl,
    notificationUrl,
    transactionId,
  }: IMethodUrlData): Observable<IMethodURLResultInterface> {
    return this.threeDSecure.run3DSMethod$(
      transactionId,
      notificationUrl,
      methodUrl,
    );
  }

  private doChallenge$(
    data: IChallengeData,
  ): Observable<IChallengeResultInterface> {
    return this.threeDSecure
      .doChallenge$(
        new ThreeDSecureVersion(data.version),
        data.payload,
        data.challengeURL,
        data.cardType,
        data.termURL,
        data.merchantData,
      )
      .pipe(
        catchError((error) => {
          this.internalMonitor.recordIssue(error, { function: "doChallenge" });
          return throwError(() => error);
        }),
      );
  }

  private cancel$(): Observable<IChallengeResultInterface> {
    return this.threeDSecure.cancelChallenge$();
  }

  private initLogs$(): void {
    const errorMessage = "Error in calculating screen height";

    this.threeDSecure.logs$.subscribe((log: ILogInterface) => {
      // LogType is not exported!
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (log.type === "error" && log.message.startsWith(errorMessage)) {
        this.internalMonitor.recordIssue(new Error(errorMessage), log);
      }
    });
  }
}
