import { deserializeError, serializeError } from "serialize-error";

interface IAppMonitorInterFrameMessage {
  error: unknown;
  context: object;
}

/**
 * Helper class for converting AppMonitor issues
 * to the right format for the internal monitor
 * when communicating across frames.
 */
export class InternalsMonitorUtil {
  /**
   * Ready the error information for the inter-frame communicator. This
   * object should be assigned to the message but .data element.
   *
   * @param error The error to transmit
   * @param context The error contxt
   * @returns The input to the interframe communication
   */
  static constructErrorMessage(
    error: Error,
    context?: object,
  ): IAppMonitorInterFrameMessage {
    return {
      error: serializeError(error),
      context: context || {},
    };
  }

  /**
   *
   * @param message Deconstructs the message that was constructed
   * and sent across the interframe communicator.
   *
   * @returns
   * The original object, in proper form.
   */
  static deconstructErrorMessage(
    message: IAppMonitorInterFrameMessage,
  ): IAppMonitorInterFrameMessage {
    return {
      error: deserializeError(message.error),
      context: message.context || {},
    };
  }
}
